/**
 * Get the initials of a name considering only the first letter of the first word and the first letter of the last word.
 *
 * @example getInitials('John Doe Jie') // JJ
 */
export function getInitials(name?: string) {
  if (!name)
    return '' // return empty string if name is not provided

  const words = name.split(' ') // split the name into words
  const initials = words.length > 1 ? `${words[0][0]}${words[words.length - 1][0]}` : words[0][0] // get the first letter of the first word and the first letter of the last word
  return initials.toUpperCase() // return the initials in uppercase
}

/**
 * Get the percentage of value corelating to past record(either monthly or weekly)
 *
 * @eg getPercentage(current: 550, previous: 500) 10.0%
 * @eg getPercentage(current: 500, previous: 550) 9.1%
 */

export function getPercentage(current: number, previous: number): string {
  const percentageChange = ((current - previous) / previous) * 100
  const formattedPercentage = percentageChange.toFixed(1)
  const sign = percentageChange >= 0 ? '+' : ''
  return `${sign}${formattedPercentage}%`
}

export function getTrend(trend: string, negative: boolean): string {
  if (!negative)
    return trend !== 'down' ? 'text-success' : 'text-warning'
  return trend !== 'down' ? 'text-warning' : 'text-success'
}

/**
 * Get the first name of a full name.
 *
 * @example getFirstName('John Doe') // John
 * @example getFirstName('John') // John
 */
export function getFirstName(fullName?: string) {
  if (!fullName)
    return '' // return empty string if name is not provided

  return fullName.split(' ')[0] // split the full name into words and get the first word
}

/**
 * Check if a given URL is a YouTube video URL.
 *
 * @example isYouTubeVideo('https://www.youtube.com/watch?v=123456') // true
 */
export function isYouTubeVideo(url: string) {
  if (!url)
    return false

  const youtubeRegex = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\//i
  return youtubeRegex.test(url)
}

/**
 *  Get the embed URL of a YouTube video.
 *
 * @example getYouTubeEmbedURL('https://www.youtube.com/watch?v=123456') // https://www.youtube.com/embed/123456
 */
export function getYouTubeEmbedURL(url?: string) {
  if (!url || !isYouTubeVideo(url))
    return url

  const youtubeId = new URL(url).searchParams.get('v')
  return `https://www.youtube.com/embed/${youtubeId}`
}

/**
 * Check if a given URL is a Loom video URL.
 *
 * @example isLoomVideo('https://www.loom.com/share/123456') // true
 * @example isLoomVideo('https://www.loom.com/embed/123456') // true
 * @example isLoomVideo('https://www.loom.com/123456') // true
 *
 */
export function isLoomVideo(url?: string | undefined) {
  if (!url)
    return false

  const loomRegex = /^(?:https?:\/\/)?(?:www\.)?loom\.com\/(?:share|embed)\/\d+/i
  return loomRegex.test(url)
}

/**
 * Get the embed URL of a Loom video.
 *
 * @example getLoomEmbedURL('https://www.loom.com/share/123456') // https://www.loom.com/embed/123456
 * @example getLoomEmbedURL('https://www.loom.com/embed/123456') // https://www.loom.com/embed/123456
 * @example getLoomEmbedURL('https://www.loom.com/123456') // https://www.loom.com/embed/123456
 */
export function getLoomEmbedURL(url?: string) {
  if (!isLoomVideo(url) || !url)
    return url

  const loomId = url.split('/').pop()
  return `https://www.loom.com/embed/${loomId}`
}

/**
 * Convert seconds to minutes and seconds with rounded seconds.
 *
 * @example convertSecondToMinute(120) // 02:00
 * @example convertSecondToMinute(90) // 01:30
 * @example convertSecondToMinute(60) // 01:00
 */
export function convertSecondToMinute(seconds: number): string {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = Math.round(seconds % 60)
  return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`
}

/**
 * Check if a given number is within a range.
 *
 * @example isWithinRange(5, 1, 10) // true
 */
export function isWithinRange(number: number | undefined, min: number | undefined, max: number | undefined) {
  if (number === undefined || min === undefined || max === undefined)
    return false

  return number >= min && number <= max
}
